import { CommonModule } from "@angular/common";
import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { IAcademy } from "@sportyano/core/models/academy-card/academy-card.model";
import { CartComponent } from "@sportyano/shared/components/cart/cart.component";
import { ChipComponent } from "@sportyano/shared/components/chip/chip.component";
import { SpecialCartDirective } from "@sportyano/shared/directives/special card/special-cart-directive.directive";
import { StopClickPropagationDirective } from "@sportyano/shared/directives/stop-click-propagation/stop-click-propagation.directive";
import { TextLimiterDirective } from "@sportyano/shared/directives/text-limiter/text-limiter.directive";

@Component({
	selector: "academy-cart",
	templateUrl: "./cart-academy.component.html",
	styleUrl: "./cart-academy.component.scss",
	standalone: true,
	imports: [
		SpecialCartDirective,
		CommonModule,
		CartComponent,
		TextLimiterDirective,
		ChipComponent,
		TranslateModule,
		StopClickPropagationDirective
	],
})
export class AcademyCartComponent {
	@Input() academy: IAcademy;
	// ViewChild
	@ViewChild(CartComponent) cartComponent!: CartComponent;
}
