<cart
	appSpecialCartDirective
	[academy]="academy"
	class="col-span-12 md:col-span-6 academy-cart block rounded-2xl"
	[title]="academy.branch_name"
	[image]="
		academy.pictures.length > 0
			? academy.pictures[0].url
			: '/assets/images/image/default/Branch-default.png'
	"
	[route]="'/main/academies/' + academy.slug"
	[type]="'academyandplayground'"
>
	<div postContent class="flex flex-col gap-3">
		<p
			class="academy-cart-description leading-normal mt-2 text-fontColor text-sm break-all break-large-text cursor-text"
		>
			{{ academy?.description }}
		</p>
		<ul
			class="exercises mt-5 flex flex-wrap justify-start gap-2 list-none text-white"
		>
			<ng-container>
				@for(sport of academy?.sports | slice : 0 : 2 ; track $index){
				<li class="chip-item">
					<chip colorHex="#f9c728" [label]="sport.name"></chip>
				</li>
				}
			</ng-container>
			@if(academy.sports.length > 2){
			<li class="chip-item">
				<chip colorHex="#f9c728" label="..."></chip>
			</li>
			}
		</ul>
		<!-- address -->
		<ng-container
			*ngTemplateOutlet="
				cartComponent?.itemDataTemplate!;
				context: {
					primeIcon: 'pi-map-marker',
					value: academy?.city_name + ' , ' + academy?.country_name
				}
			"
		></ng-container>
	</div>
</cart>
