import { Directive, DoCheck, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { IAcademy } from '@sportyano/core/models/academy-card/academy-card.model';
import { PlayerProfile } from '@sportyano/core/models/player-card.model';
import { IPlaygroundTopRated } from '@sportyano/core/models/playgrounds/playground.interface';
import { Player } from '@sportyano/core/models/single-player.model';

@Directive({
  selector: '[appSpecialCartDirective]',
  standalone: true,
})
export class SpecialCartDirective implements OnInit, DoCheck {
  @Input() playerData!: PlayerProfile;
  @Input() academy!: IAcademy;
  @Input() playground!: IPlaygroundTopRated;
  @Input() singlePlayer!: Player;

  private previousPlayerPlan: string | null = null;
  private previousAcademyPlan: string | null = null;
  private previousPlaygroundPlan: string | null = null;
  private previousSinglePlayerPlan: string | null = null;

  constructor(private _elementRef: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    this.applyBorder();
  }

  ngDoCheck(): void {
    if (this.playerData?.plan_name !== this.previousPlayerPlan ||
        this.academy?.plan_name !== this.previousAcademyPlan ||
        this.playground?.plan_name !== this.previousPlaygroundPlan ||
		this.singlePlayer?.plan_name !== this.previousSinglePlayerPlan
        ) {

      this.previousPlayerPlan = this.playerData?.plan_name || null;
      this.previousAcademyPlan = this.academy?.plan_name || null;
      this.previousPlaygroundPlan = this.playground?.plan_name || null;
	  this.previousSinglePlayerPlan = this.singlePlayer?.plan_name || null;

      this.applyBorder();
    }
  }


  private applyBorder(): void {
    if (this.playerData?.plan_name === 'BASIC' ||
        this.academy?.plan_name === 'BASIC' ||
        this.playground?.plan_name === 'BASIC' ||
		this.singlePlayer?.plan_name === 'BASIC') {
      this.renderer.setStyle(this._elementRef.nativeElement, 'border', '3px solid red');
    } else if (this.playerData?.plan_name === 'PRO' ||
               this.academy?.plan_name === 'PRO' ||
               this.playground?.plan_name === 'PRO' ||
			   this.singlePlayer?.plan_name === 'PRO') {
      this.renderer.setStyle(this._elementRef.nativeElement, 'border', '3px solid green');
    } else if (this.playerData?.plan_name === 'PREMIUM' ||
               this.academy?.plan_name === 'PREMIUM' ||
               this.playground?.plan_name === 'PREMIUM' ||
			   this.singlePlayer?.plan_name === 'PREMIUM') {
      this.renderer.setStyle(this._elementRef.nativeElement, 'border', '3px solid #ffbf00');
    } else {
      this.renderer.removeStyle(this._elementRef.nativeElement, 'border');
    }
  }
}
